//引入
import { JSEncrypt } from "jsencrypt";

/*
 *@描述: 加密参数
 *@参数: 需要加密的参数
 *@作者: 金涛
 *@日期: 2022-06-24 18:42:27
 */
export function setEncryption(param) {
  // 后台给的公钥
  const publicKey =
    "MIICdQIBADANBgkqhkiG9w0BAQEFAASCAl8wggJbAgEAAoGBAJcTS8sS96ZpblU1QDDq4XJ9H01YBMKK5VVZT0PzYFiewty8OQdLGnAHJMvljGaHNEP4oZyQCU7S4cL7JC+e2Spi/pF8I4kSRqH/P6F4RiE8ryZwTO1VaQQXhHtUubrhTgWRu5Y+OKgjykwhXPciuhLa7mOdvQgxdLHiM7VeTkvnAgMBAAECgYAL0i60DR/jnYxMjoYfTcGmO7QfUSvOLSAUtZBGONzpEkMdEn1D3NSMv4JZkfSScTHdv66lmX+g809G+m+yajODxp347CS0ooNTLRZV0WeNne+1IDEwW8608WaFtpZ4Z05bieZRZ5/et/MWJ/sQqy1Ytwlru5xiOxQ4s2lXUIMqwQJBANkYN5qsKtls1VAOa915+hMunkaMYEv86xQl8zaogJfKo/lw3gFDgfj+PtjPSJ5aX7diwnwstoLNBXRY2TKdfO0CQQCyJkY0p6dYzYjFFRuoger7Tts9AZ8onsfvOjuU31sloZd/cBiXJydf0u+Tkz2U2Opu672ijm4QlFCXScAfL6WjAj8wVjQLPEpOHu1f5dYeego0Oy0CiD7orGerFMgSY3I+WeAoqfjET0OhLPrRS1Xf7D9jlZm4DDyW4w9T2HSD0I0CQQCJRjzq7hVhtF8jnnRFkHbFV6pQhCNkZZWinbizqrjNcosOxA3uy1jn2d6KVPcq70sWVck2rZHabK79JPNKFKkJAkBudwe7/uZiwxcihiJZT6Z4oBhpROofJu0hoNxrp5L99/yCYk1plk28psz3pySjz/WQedIpPqhSh82KxMDymiyA";
  let encryptor = new JSEncrypt(); // 新建JSEncrypt对象
  encryptor.setPublicKey(publicKey); // 设置公钥

  return encryptor.encrypt(JSON.stringify(param)); // 对密码进行加密
}
